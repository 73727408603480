<template>
  <div>
    <div class="flex md:space-x-4 lg:space-x-8 xl:space-x-16 py-12 md:py-24">
      <div class="md:max-w-xl flex-1 lg:pt-12">
        <h2 class="font-bold text-2xl xl:text-3xl 2xl:text-4xl text-blue-800 mb-2 xl:mb-4">DSM Proteases <br>Matrix Calculator</h2>
        <h3 class="text-xl md:text-3xl mb-4 xl:mb-8 2xl:mb-12 text-left">Create your account</h3>

        <form @submit.prevent="onSubmit">
          <div class="row flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mb-5">
            <form-field-input
              v-model.trim="name"
              class="flex-1"
              size="medium"
              field-label="Name *"
              placeholder="Enter Full Name"
              :validation-class="{invalid: $v.name.$error || errors.name, valid: !$v.name.$invalid && !errors.name}"
              @blur="$v.name.$touch()"
            >
              <template #helper v-if="$v.name.$error || errors.name">
                <span
                  v-if="!$v.name.required"
                  class="invalid"
                >
                  Name can't be empty
                </span>

                <span
                  v-if="errors.name"
                  class="invalid"
                >
                  {{ errors.name[0] }}
                </span>
              </template>
            </form-field-input>
          </div>

          <div class="row flex space-x-4 md:space-x-8 mb-5">
            <form-field-input
              v-model.trim="email"
              class="flex-1"
              size="medium"
              type="email"
              field-label="E-mail Address *"
              placeholder="example@dsm.com"
              :validation-class="{invalid: $v.email.$error || errors.email, valid: !$v.email.$invalid && !errors.email}"
              @blur="$v.email.$touch()"
            >
              <template #helper v-if="$v.email.$error || errors.email">
                <span
                  v-if="!$v.email.required"
                  class="invalid"
                >
                  Email can't be empty
                </span>

                <span
                  v-if="!$v.email.email"
                  class="invalid"
                >
                  Email isn't valid
                </span>

                <span
                  v-if="errors.email"
                  class="invalid"
                >
                  {{ errors.email[0] }}
                </span>
              </template>
            </form-field-input>

            <form-field-input
              v-model.trim="phone"
              class="flex-1"
              size="medium"
              field-label="Phone Number"
              placeholder="Enter Phone Number"
            >
            </form-field-input>
          </div>

          <div class="row flex space-x-4 md:space-x-8 mb-5">
            <form-field-input
              v-model.trim="company"
              class="flex-1"
              size="medium"
              field-label="Company *"
              :validation-class="{invalid: $v.company.$error || errors.company, valid: !$v.company.$invalid && !errors.company}"
              @blur="$v.company.$touch()"
            >
              <template #helper v-if="$v.company.$error || errors.company">
                <span
                  v-if="!$v.company.required"
                  class="invalid"
                >
                  Company can't be empty
                </span>

                <span
                  v-if="errors.company"
                  class="invalid"
                >
                  {{ errors.company[0] }}
                </span>
              </template>
            </form-field-input>

            <form-field-select
              v-model="country"
              class="flex-1"
              field-label="Country *"
              option-label="country"
              track-by="code"
              disabled-option-value="Select country"
              :validation-class="{invalid: $v.country.$error || errors.country, valid: !$v.country.$invalid && !errors.country}"
              :options="countries"
            >
              <template #helper v-if="$v.country.$error || errors.country">
                <span
                  v-if="!$v.country.required"
                  class="invalid"
                >
                  Select Country
                </span>

                <span
                  v-if="errors.country"
                  class="invalid"
                >
                  {{ errors.country[0] }}
                </span>
              </template>
            </form-field-select>
          </div>

          <div class="row flex space-x-4 md:space-x-8 mb-5">
            <form-field-input
              v-model.trim="password"
              class="flex-1"
              size="medium"
              type="password"
              field-label="New Password *"
              :validation-class="{invalid: $v.password.$error || errors.password, valid: !$v.password.$invalid && !errors.password}"
              @blur="$v.password.$touch()"
            >
              <template #helper v-if="$v.password.$error || errors.password">
                <span
                  v-if="!$v.password.required"
                  class="invalid"
                >
                  Password can't be empty
                </span>

                <span
                  v-if="!$v.password.minLength"
                  class="invalid"
                >
                  min Length is 6
                </span>

                <span
                  v-if="errors.password"
                  class="invalid"
                >
                  {{ errors.password[0] }}
                </span>
              </template>
            </form-field-input>

            <form-field-input
              v-model.trim="password_confirmation"
              class="flex-1"
              size="medium"
              type="password"
              field-label="New Password *"
              :validation-class="{invalid: $v.password_confirmation.$error || errors.password_confirmation, valid: $v.password_confirmation.sameAsPassword && !$v.password.$invalid && !errors.password_confirmation}"
              @blur="$v.password_confirmation.$touch()"
            >
              <template #helper v-if="$v.password_confirmation.$error || errors.password_confirmation">
                <span
                  v-if="!$v.password_confirmation.required"
                  class="invalid"
                >
                  Passwords must be identical
                </span>

                <span
                  v-if="!$v.password_confirmation.minLength && $v.password_confirmation.sameAsPassword"
                  class="invalid"
                >
                  min Length is 6
                </span>

                <span
                  v-if="errors.password_confirmation"
                  class="invalid"
                >
                  {{ errors.password_confirmation[0] }}
                </span>
              </template>
            </form-field-input>
          </div>

          <div class="row pt-4 border-t border-grey-mediumLight mb-9">
            <p class="m-0 text-sm lg:text-base">
              <input type="checkbox" class="mr-2" v-model="terms">
              I agree to the
              <a class="link font-medium text-brand-semiMedium underline hover:no-underline" href="#" target="_blank">License Agreement</a>
              and
              <a class="link font-medium text-brand-semiMedium underline hover:no-underline" href="#" target="_blank">Privacy Policy</a>.
            </p>
            <small v-if="$v.terms.$error" class="text-red-400 text-xs">Please agree with terms</small>
          </div>

          <div class="row">
            <button type="submit" class="btn-success">Register Now</button>
          </div>
        </form>
      </div>

      <div class="img flex-1 hidden md:block relative">
        <img src="~@/assets/images/page-img-round.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import FormFieldSelect from '@/components/form/FormFieldSelect';
import { validationMixin } from 'vuelidate';
import { mapState, mapActions } from 'vuex';
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'Register',

  components: {
    FormFieldInput,
    FormFieldSelect
  },

  mixins: [validationMixin],

  data: () => ({
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    password: '',
    password_confirmation: '',
    terms: false,
    errors: {}
  }),

  validations: {
    name: {
      required,
    },

    email: {
      required,
      email
    },

    company: {
      required
    },

    country: {
      required
    },

    password: {
      required,
      minLength: minLength(6)
    },

    password_confirmation: {
      sameAsPassword: sameAs('password')
    },

    terms: {
      sameAs: sameAs(() => true)
    }
  },

  mounted() {
    if (!this.countries.length) {
      this.getCountries()
    }

    this.$store.commit('setNavigation')
  },

  methods: {
    ...mapActions('countries', [
      'fetchCountries'
    ]),

    ...mapActions({
      signUp: 'auth/signUp'
    }),

    onSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      /* eslint-disable camelcase */
      const {name, email, phone, company, country, password, password_confirmation} = this

      const formData = {
        name,
        email,
        phone,
        company,
        country,
        password,
        password_confirmation
      }

      this.errors = {}

      this.signUp(formData)
          .then(() => {
            this.$router.push({name: 'thankYou'})
          })
          .catch((error) => {
            this.errors = (error.data && error.data.errors) || {}
          })
    },

    getCountries() {
      return this.fetchCountries()
    }
  },

  computed: {
    ...mapState({
      countries: state => state.countries.countries
    })
  },

  watch: {
    '$v.$invalid'() {
      this.errors = {}
    }
  }
};
</script>

<style scoped>
</style>
